import "../css/moodboard-masonry.css";
import ReactPlayer from "react-player";
import ItemBar from "./ItemBar/ItemBar";

const renderVideo = (src) => {
  if (src.includes("tiktok")) {
    const videoId = src.split("video/")[1];
    const embedUrl = `https://www.tiktok.com/embed/${videoId}`;
    return <iframe className="player-yt" src={embedUrl} />;
  }

  return <ReactPlayer className="player-yt" url={src} controls={true} />;
};

function TileVideo({ currentFile, view, ...props }) {
  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      />
      {/* <ReactJWPlayer
        className="player"
        playerId={props.id.toString()}
        playerScript="https://cdn.jwplayer.com/libraries/s7lNp89j.js"
        file={props.src}
        image={
          props.image
            ? props.image.replace("h=630", "h=200").replace("w=1200", "w=350")
            : null
        }
      /> */}
      {renderVideo(props.src)}

      {/* <video className="player" controls>
            <source src={props.src} type="video/mp4"/>
        </video> */}
    </div>
  );
}

export default TileVideo;
