import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactPlayer from "react-player";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "../css/tabs.css";
import "../css/rodal.css";
import "../css/pexels.css";
import isImageUrl from "is-image-url";
import { io } from "socket.io-client";
import { Spinner } from "reactstrap";
import { Button, notification } from "antd";
import { CircleProgress } from "react-gradient-progress";
import axios from "axios";
import moment from "moment";
import YoutubePlayer from "./YoutubePlayer/YoutubePlayer";
import IspotPlayer from "./IspotPlayer/IspotPlayer";
import { InstagramEmbed } from "react-social-media-embed";

import youtubeLogo from "../images/social-media/youtube.png";
import googleLogo from "../images/social-media/google.png";
import vimeoLogo from "../images/social-media/vimeo.png";
import ispotLogo from "../images/social-media/ispot.png";
import instaLogo from "../images/social-media/insta.png";
import tiktokLogo from "../images/social-media/tiktok.png";

import "./Embed.scss";

export const ytPattern =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const instaPattern =
  /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com\/(?:p|reel|tv)\/[a-zA-Z\d_-]+\/?)(?:\?.*|)$/i;

export const tiktokPattern =
  /https?:\/\/(?:www\.)?tiktok\.com\/\S*\/video\/(\d+)|https?:\/\/(?:www\.)?vm.tiktok.com\/\S*\//;
export const vimeoPattern =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
const isValidUrl = new RegExp(
  "^(https?:\\/\\/)" + // protocol (mandatory)
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);
// const isValidDocs =
//   /^https:\/\/docs\.google\.com\/document\/d\/e\/(?:[\w-]+)\/pub(?:\?embedded=true)?$/;
const isValidDocs = /docs\.google\.com\/document/;

const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket"] });
socket.on("connect", () => {
  console.log("Connected to SocketIO Server.");
});

export default function Embed(props) {
  const { videoInfo, onScreenshot } = props;
  const [playerUrl, setPlayerUrl] = useState(null);
  const [url, setUrl] = useState(null);
  const [contentDisplay, setContentDisplay] = useState(false);
  const [mediaType, setMediaType] = useState(`video`);
  const [mediaInfo, setMediaInfo] = useState(null);

  const [percents, setPercents] = useState(0);
  const [isDownloading, setDownloading] = useState(false);
  const [downloadedUrl, setDownloadedUrl] = useState("");
  const [isCapturing, setCapturing] = useState(false);
  const [isTrimming, setTrimming] = useState(false);

  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [trimmedUrl, setTrimmedUrl] = useState(null);
  const [originUrl, setOriginUrl] = useState("");

  const [trimText, setTrimText] = useState("Start Trim");
  const [trimTiming, setTrimTiming] = useState({});

  const [displayCurrentTime, setDisplayCurrentTime] = useState("0");

  const player = useRef();

  const isYoutubeUrl = useMemo(() => {
    return (
      playerUrl?.includes("youtube.com") || playerUrl?.includes("youtu.be")
    );
  }, [playerUrl]);

  const isTiktokUrl = useMemo(() => {
    return playerUrl?.includes("tiktok.com");
  }, [playerUrl]);

  const isEditable = useMemo(() => {
    return (
      (isTiktokUrl || isYoutubeUrl) &&
      mediaType !== "image" &&
      window.innerWidth >= 480
    );
  }, [isTiktokUrl, isYoutubeUrl, mediaType]);

  const showSelectBtn = useMemo(() => {
    if (isTiktokUrl) {
      return downloadedUrl ? true : false;
    }
    return true;
  });
  const isDownloadable = useMemo(() => {
    return (
      mediaType === "video" ||
      mediaType === "tiktok" ||
      mediaType === "youtube-short" ||
      mediaType === "ispot"
    );
  }, [mediaType]);

  useEffect(() => {
    if (videoInfo) {
      setOriginUrl(videoInfo.fileUrl);
      setPlayerUrl(videoInfo.fileUrl);
      !videoInfo?.thumbnailUrl.includes("null") &&
        setThumbnailUrl(videoInfo?.thumbnailUrl);
      if (
        videoInfo &&
        !ytPattern.test(videoInfo.fileUrl) &&
        !vimeoPattern.test(videoInfo.fileUrl) &&
        isValidUrl.test(videoInfo.fileUrl)
      ) {
        setDownloadedUrl({
          path: `trimmedVideo${videoInfo.fileUrl.split("trimmedVideo")[1]}`,
        });
      }
    }
  }, [videoInfo]);

  useEffect(() => {
    const input = document.getElementById("txt-embed-url");
    input.setAttribute("autofocus", "");
    input.focus();
    input.select();
  }, []);

  //Ref to handle the current instance of ffmpeg when loaded
  const ffmpeg = useRef(null);
  //Function handling loading in ffmpeg
  const load = async () => {
    try {
      await ffmpeg.current.load();
    } catch (error) {
      //   console.log(error);
    }
  };

  const isShowTrim = useMemo(() => {
    if (trimmedUrl || (videoInfo && !ytPattern.test(videoInfo.fileUrl))) {
      return false;
    }
    return true;
  }, [videoInfo, trimmedUrl]);

  useEffect(() => {
    socket.on(`download-video-progress`, (data) => {
      setPercents(Math.trunc(data.percents));
    });

    socket.on(`take-screenshot-success`, (data) => {
      //   setThumbnailUrl(data.screenshotUrl);
      if (data.screenshotUrl) {
        onScreenshot(data.screenshotUrl, {
          source:
            mediaInfo?.source ||
            (mediaType === "video" ? "youtube" : mediaType),
        });
        setCapturing(false);
      }
    });

    socket.on(`trim-video-success`, (data) => {
      setTrimming(false);
      setPlayerUrl(data.path.url);
      setTrimmedUrl(data.path);
    });
    return () => {
      socket.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    socket.on(`download-video-success`, (data) => {
      console.log("download-success", data);
      setDownloadedUrl(data);
      setDownloading(false);
      setMediaInfo(data);
      if (mediaType === "tiktok") {
        setPlayerUrl(data.fileName);
      }
    });
    return () => {
      socket.off(`download-video-success`);
    };
  }, [mediaType]);

  useEffect(() => {
    if (originUrl?.length > 0) {
      handleValidate();
    }
  }, [originUrl]);

  const handleDownloadVideo = () => {
    setPercents(0);
    if (!playerUrl) {
      console.log("Url is empty");
      return;
    }
    socket.emit(`download-video`, { url: originUrl || playerUrl });
    setDownloading(true);
  };

  const styles = {
    background: "#191A1F",
    width: "70%",
    height: "fit-content",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    padding: "40px",
  };

  const handleValidate = () => {
    document.getElementById("txt-embed-url").classList.add("is-invalid");
    document.getElementById("txt-embed-url").classList.remove("is-valid");
    const url = originUrl;
    if (url.length === 0) {
      return;
    }

    if (tiktokPattern.test(url)) {
      setMediaType("tiktok");
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");
      setContentDisplay(true);
      const videoId = url.split("video/")[1];
      setUrl(`https://www.tiktok.com/embed/${videoId}`);
      setOriginUrl(url);
      setPlayerUrl(`https://www.tiktok.com/embed/${videoId}`);
      return;
    }

    if (instaPattern.test(url)) {
      setMediaType("instagram");
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");
      setContentDisplay(true);
      setUrl(url);
      setPlayerUrl(url);
      return;
    }

    if (url.includes("docs.google.com")) {
      if (isValidDocs.test(url)) {
        document.getElementById("txt-embed-url").classList.remove("is-invalid");
        document.getElementById("txt-embed-url").classList.add("is-valid");
        setMediaType("docs");
        setContentDisplay(true);
        setUrl(url);
        setPlayerUrl(url);
        return;
      } else {
        return;
      }
    }

    if (isImageUrl(url)) {
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");
      setMediaType("image");
      setContentDisplay(true);
      setUrl(url);
      return;
    }

    if (ytPattern.test(url)) {
      setMediaType("video");
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");

      const id =
        new URL(url).searchParams.get("v") ||
        new URL(url).pathname.split("/")[1];
      setPlayerUrl(`https://www.youtube.com/embed/${id}`);
      setContentDisplay(true);
      setUrl(url);
      return;
    }

    if (vimeoPattern.test(url)) {
      setMediaType("video");
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");
      const id = url.split("/").pop();
      setPlayerUrl(`https://player.vimeo.com/video/${id}`);
      setContentDisplay(true);
      setUrl(url);
      return;
    }

    if (url.includes("youtube.com/shorts")) {
      setMediaType("youtube-short");
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");
      setPlayerUrl(url);
      setContentDisplay(true);
      setUrl(url);
      return;
    }

    if (url.includes("ispot.tv")) {
      setMediaType("ispot");
      document.getElementById("txt-embed-url").classList.remove("is-invalid");
      document.getElementById("txt-embed-url").classList.add("is-valid");
      setPlayerUrl(url);
      setContentDisplay(true);
      setUrl(url);
      return;
    }
  };

  const handleSelect = () => {
    if (
      document
        .getElementById("txt-embed-url")
        .className.split(" ")
        .indexOf("is-valid") !== -1
    ) {
      const selectedType =
        mediaType === "video" ||
        mediaType === "tiktok" ||
        mediaType === "instagram" ||
        mediaType === "youtube-short" ||
        mediaType === "ispot"
          ? "video"
          : mediaType;
      const selectedVideoUrl = mediaType === "tiktok" ? playerUrl : originUrl;
      let thumbnail;
      if (mediaType === "docs") {
        thumbnail =
          "https://vectorseek.com/wp-content/uploads/2021/12/Google-Docs-Logo-Vector-scaled.jpg";
      } else {
        thumbnail = thumbnailUrl;
      }
      props.onSelect(trimmedUrl ? playerUrl : selectedVideoUrl, selectedType, {
        thumbnailUrl: thumbnail,
        title: mediaInfo?.title,
        size: mediaInfo?.size,
        source:
          mediaInfo?.source || (mediaType === "video" ? "youtube" : mediaType),
      });
      props.onClose();
    } else {
      document.getElementById("txt-embed-url").classList.add("is-invalid");
      document.getElementById("txt-embed-url").classList.remove("is-valid");
    }
  };

  const handleUpdate = () => {
    props.onEdit({
      ...videoInfo,
      thumbnailUrl,
      fileUrl: playerUrl,
      title: mediaInfo?.title,
      size: mediaInfo?.size,
      source:
        mediaInfo?.source || (mediaType === "video" ? "youtube" : mediaType),
    });
  };

  const handleCapture = () => {
    // editorRef.current.addScreenshot();

    setCapturing(true);
    socket.emit(`take-screenshot`, {
      path: trimmedUrl?.path || downloadedUrl.path,
      cutAtSecond: player.current.getCurrentTime(),
    });
  };

  const handleTrimming = (trimEndTime) => {
    // const { trimStartTime, trimEndTime } = editorRef.current.startTrim();
    socket.emit(`trim-video`, {
      path: downloadedUrl.path,
      startTime: trimTiming.start,
      endTime: trimEndTime,
    });
    setTrimming(true);
  };

  const onBeginTrim = () => {
    setTrimText(`Select start time`);
  };

  const trimming = () => {
    if (trimText === "Start Trim") {
      onBeginTrim();
    } else if (trimText === "Select start time") {
      const startTime = player.current.getCurrentTime();
      notification.info({
        message: `Selected start time: ${moment
          .utc(startTime * 1000)
          .format("mm:ss")}`,
      });
      setTrimTiming({ ...trimTiming, start: startTime });
      setTrimText(`Select end time`);
    } else if (
      trimText === "Select end time" &&
      player.current.getCurrentTime() <= trimTiming.start
    ) {
      notification.error({
        message: "End time must be larger than start time",
      });
    } else {
      const endTime = player.current.getCurrentTime();
      notification.info({
        message: `Selected end time: ${moment
          .utc(endTime * 1000)
          .format("mm:ss")}`,
      });
      handleTrimming(endTime);
      setTrimText("Start Trim");
    }
  };

  const onCloseModal = () => {
    props.onClose();
    try {
      if (downloadedUrl?.fileName) {
        axios.post(
          `${process.env.REACT_APP_API_URL}/moodboards/delete-local-video`,
          { fileUrl: downloadedUrl.fileName }
        );
      }
    } catch (e) {
      console.log("Something went wrong when delete video", e);
    }
  };

  const onVideoProgress = ({ playedSeconds }) => {
    setDisplayCurrentTime(moment.utc(playedSeconds * 1000).format("mm:ss"));
  };

  return (
    <Rodal
      visible={true}
      className="rodal-embed"
      onClose={onCloseModal}
      closeOnEsc={false}
      showCloseButton={false}
      customStyles={styles}
    >
      <div className="container embed-container" style={{ color: "white" }}>
        <div className="row ">
          <div className="title">Embed Link</div>
          <div className="col-12">
            <input
              type="text"
              className="embed-input"
              id="txt-embed-url"
              placeholder="Paste a YouTube, Vimeo, Tiktok, Instagram, Ispot or an Image URL"
              value={originUrl}
              onChange={(e) => {
                setOriginUrl(e.target.value);
                setMediaInfo(null);
              }}
            />

            <div className="invalid-feedback text-left">
              Link is not supported
            </div>
          </div>
        </div>
        <div
          className="row mt-10"
          style={contentDisplay ? {} : { display: "none" }}
        >
          <div className="col-12 pexel-gallery">
            {mediaType === "ispot" && !downloadedUrl && !isDownloading && (
              <IspotPlayer url={playerUrl} />
            )}
            {mediaType === "youtube-short" &&
              !downloadedUrl &&
              !isDownloading && (
                <YoutubePlayer url={playerUrl} style={{ height: "390px" }} />
              )}

            {isDownloadable && playerUrl && isDownloading && (
              <div
                style={{
                  width: 200,
                  height: 200,
                  marginInline: "auto",
                  marginTop: "10%",
                }}
              >
                <CircleProgress
                  percentage={percents}
                  primaryColor={["#00d4ff", "#090979"]}
                  fontSize="44px"
                />
                <div className="downloading-text">Downloading...</div>
              </div>
            )}

            {mediaType === "tiktok" && !downloadedUrl && !isDownloading && (
              <iframe className="embed-player" src={playerUrl} />
            )}

            {(mediaType === "youtube-short" ||
              mediaType === "ispot" ||
              mediaType === "tiktok") &&
              downloadedUrl && (
                <ReactPlayer
                  className="embed-player"
                  ref={player}
                  url={trimmedUrl?.url || downloadedUrl.fileName || playerUrl}
                  controls={true}
                  onProgress={(play) => onVideoProgress(play)}
                />
              )}
            {mediaType === "docs" && (
              <iframe src={url} className="embed-player" />
            )}
            {mediaType === "video" &&
              playerUrl &&
              !playerUrl.includes("tiktok") &&
              !isDownloading && (
                <ReactPlayer
                  className="embed-player"
                  ref={player}
                  url={playerUrl}
                  controls={true}
                  onProgress={(play) => onVideoProgress(play)}
                />
              )}
            {mediaType === "instagram" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed url={playerUrl} />
              </div>
            )}
            {mediaType === "image" && url && (
              <img id="player" className="embed-player" src={url} />
            )}
          </div>
        </div>
        {thumbnailUrl?.length > 0 && (
          <div>
            Screenshot:
            <div>
              <img src={thumbnailUrl} style={{ width: "20%" }} />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col text-right mt-10 button-wrapper">
            <button
              onClick={onCloseModal}
              className="btn btn-styled mt-0 btn-new cancel-button"
            >
              Cancel
            </button>
            {downloadedUrl && contentDisplay && (
              <>
                {isShowTrim && (
                  <button
                    className="btn btn-styled mt-0 btn-new"
                    onClick={trimming}
                    disabled={isDownloading || isTrimming}
                  >
                    {isTrimming ? (
                      <Spinner size="sm"> </Spinner>
                    ) : trimText.includes("start time") ||
                      trimText.includes("end") ? (
                      `${trimText}: ${displayCurrentTime}`
                    ) : (
                      trimText
                    )}
                  </button>
                )}
                <button
                  className="btn btn-styled mt-0 btn-new "
                  onClick={handleCapture}
                  disabled={isDownloading || isCapturing || isTrimming}
                >
                  {isCapturing ? (
                    <Spinner size="sm"> </Spinner>
                  ) : (
                    "Add screenshot"
                  )}
                </button>
              </>
            )}
            <button
              className="btn btn-styled mt-0 btn-new "
              onClick={handleDownloadVideo}
              disabled={
                isDownloading || !isEditable || downloadedUrl || !contentDisplay
              }
            >
              {isDownloading ? "Please wait..." : "Edit Video"}
            </button>
            <button
              className="btn btn-styled mt-0 btn-new "
              id="select-button"
              onClick={videoInfo ? handleUpdate : handleSelect}
              disabled={
                isDownloading || isTrimming || !showSelectBtn || !contentDisplay
              }
            >
              {videoInfo
                ? "Update"
                : mediaType === "image" ||
                  mediaType === "docs" ||
                  mediaType === "instagram"
                ? "Select"
                : "Embed"}
            </button>
          </div>
        </div>
        <div className="social-media-container">
          <span>Effortlessly integrates with the tools you already love.</span>
          <div className="social-media">
            <img src={youtubeLogo} />
            <img src={googleLogo} />
            <img src={vimeoLogo} />
            <img src={ispotLogo} />
            <img src={instaLogo} />
            <img src={tiktokLogo} />
          </div>
        </div>
      </div>
    </Rodal>
  );
}
